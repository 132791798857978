import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import SectionLeft from './sectionLeft';
import SectionRight from './sectionRight';

export default function Service() {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      markdownRemark {
        frontmatter {
          mgmt_recording {
            category
            items
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }

          consult_compliance {
            category
            items
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          report_analysis {
            category
            items
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          payroll_processing {
            category
            items
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  const { frontmatter } = data.markdownRemark;
  const sections = Object.entries(frontmatter);
  return (
    <>
      {sections.map(([key, value], index) =>
        index % 2 === 0 ? (
          <SectionLeft
            key={key}
            name={value.category}
            items={value.items}
            image={value.image.childImageSharp.gatsbyImageData}
          />
        ) : (
          <SectionRight
            key={key}
            name={value.category}
            items={value.items}
            image={value.image.childImageSharp.gatsbyImageData}
          />
        )
      )}
    </>
  );
}
