import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

export default function SectionRight({ name, items, image }) {
  return (
    <div className="relative overflow-hidden bg-secondary/30 py-24">
      <div className=" mx-auto max-w-7xl md:px-6 lg:px-8 ">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-center">
          <div className="order-first sm:px-6 lg:order-first lg:px-0">
            <div className="relative isolate overflow-hidden sm:mx-auto sm:max-w-2xl sm:rounded-3xl lg:mx-0 lg:max-w-none">
              <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                <GatsbyImage
                  image={image}
                  alt={name}
                  width={2432}
                  className="service-image"
                />
              </div>
              <div
                className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                aria-hidden="true"
              />
            </div>
          </div>
          <div className="px-6 lg:px-0 lg:pl-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {name}
              </p>
              <dl className="mt-10 max-w-xl space-y-4 text-base leading-7 text-gray-600 lg:max-w-none">
                {items.map((item) => (
                  <div key={item} className="relative pl-3">
                    <dt className="inline font-normal text-gray-900">{item}</dt>{' '}
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
