import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Contact from '../components/contact';
import HeroSection from '../components/heroSection';
import Service from '../components/service';

function Index() {
  return (
    <Layout>
      <Seo title="Full Service Bookkeeping" />
      <HeroSection />
      <Service />
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0 lg:left-1/2">
          <StaticImage
            src="../images/taylor-munroe-bookkeeping.jpg"
            quality={95}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Photo of Taylor Munroe"
            className="h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full"
          />
        </div>
        <Contact />
      </div>
    </Layout>
  );
}

export default Index;
