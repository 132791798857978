import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const benefits = [
  'Financial Management and Recording',
  'Financial Reporting and Analysis',
  'Financial Consulting and Compliance',
  'Payroll Processing',
];

export default function HeroSection() {
  return (
    <div className="py-0 sm:py-32">
      <div className="absolute inset-0 isolate">
        <StaticImage
          src="../images/stationary-munroe.jpg"
          quality={95}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Munroe Bookkeeping Logo"
          className="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
        />
        <div className="absolute inset-0 bg-white mix-blend-multiply" />
      </div>
      <div className="relative isolate">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="mx-auto flex max-w-2xl flex-col gap-16 bg-white px-6 py-16 ring-1 ring-white/90 sm:rounded-3xl sm:p-8 lg:mx-0 lg:max-w-none lg:flex-row lg:items-center lg:py-20 xl:gap-x-20 xl:px-20">
            <div className="mx-auto">
              <StaticImage
                src="../images/munroe-bookkeeping-logo.png"
                quality={95}
                formats={['AUTO', 'WEBP', 'AVIF']}
                alt="Photo of Taylor Munroe"
                className="smd:h-96 aspect-square h-56 flex-none object-cover  lg:h-auto lg:max-w-sm"
              />
              <h2 className="text-center text-3xl font-bold italic tracking-tight text-primary sm:text-3xl">
                Balance is Key
              </h2>
            </div>
            <div className="w-full flex-auto">
              <p className="mt-6 text-lg leading-8 text-primary">
                Munroe Bookkeeping, we keep you in balance. Let us handle your
                businesses’ finances, no matter the size.
              </p>
              <p className="mt-6 text-lg leading-8 text-primary">
                We specialize in financial planning, administrative services,
                monthly bank and credit card reconciliation, source, WSIB, and
                GST/HST remittance, payroll services, and all your businesses’
                financial needs.
              </p>
              <ul
                role="list"
                className="mt-10 grid grid-cols-1 gap-x-8 gap-y-3 text-base leading-7 text-primary sm:grid-cols-2"
              >
                {benefits.map((benefit) => (
                  <li key={benefit} className="flex gap-x-3">
                    <CheckCircleIcon
                      className="h-7 w-5 flex-none"
                      aria-hidden="true"
                    />
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
